<template>
	<div class="loader">
		<svg class="loader-circle" viewBox="25 25 50 50">
			<title>Loading Circle</title>
			<circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"/>
		</svg>
		<p class="loader-text">{{ loaderMessage }}</p>
	</div>
</template>

<script>
export default {
	props: {
		loaderMessage: {
			type: String,
			default: "Loading...",
		},
	},
};
</script>

<style lang="scss" scoped>
    .loader {
        position: relative;
        width: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        .loader-text {
            position: absolute;
            width: max-content;
            left: 50%;
            bottom: -2rem;
            transform: translateX(-50%);
            font-family: var(--font-family-sans-serif);
            color: rgba(0, 0, 0, 0.2);
            margin: 0;
        }

        .loader-circle {
            animation: rotate 2s linear infinite;
            height: 100%;
            transform-origin: center center;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        .path {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            animation: dash 1.5s ease-in-out infinite;
            stroke-linecap: butt;
            stroke: rgba(0, 0, 0, 0.2);
            stroke-width: 5px;
        }
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
</style>