<template>
    <vue-modal :visible="isModalOpen" @on-close="closeModal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-robot"></span>&nbsp;Edit robots.txt</h5>
        </template>

        <p>Here you can edit the content of robots.txt. Enter or paste in the content and click save to update.</p>
        <textarea class="w-100" rows="10" name="content" id="robots_content" :value="robotsContent"></textarea>

        <template #footer>
            <button type="button" class="btn btn-outline-primary" @click.prevent="closeModal">Close</button>
            <button type="button" class="btn btn-primary ml-1" @click.prevent="updateForm" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Update' }}</button>
        </template>
    </vue-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'SiteConfigRobotsModal',
    props: ["isModalOpen"],
    computed: {
        ...mapState('siteConfig/robots', {
            robotsContent: state => state.robotsContent,
            isModalProcessing: state => state.isModalProcessing,
        })
    },
    methods: {
        ...mapActions('siteConfig/robots', [
            'closeModal',
            'setRobotsContent',
            'updateForm',
        ]),

    }
}
</script>