<template>
    <vue-modal :visible="isModalOpen" @on-close="closeModal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-map"></span>&nbsp;Edit sitemap.xml</h5>
        </template>

        <p>Here you can edit the content of sitemap.xml. Enter or paste in the content and click save to update.</p>
        <textarea class="w-100" rows="10" name="content" id="sitemap_content" :value="sitemapContent"></textarea>

        <template #footer>
            <button type="button" class="btn btn-outline-primary" @click.prevent="closeModal">Cancel</button>
            <button type="button" class="btn btn-primary ml-1" @click.prevent="updateForm" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Update' }}</button>
        </template>
    </vue-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'SiteConfigSitemapModal',
    props: ["isModalOpen"],
    computed: {
        ...mapState('siteConfig/sitemap', {
            sitemapContent: state => state.sitemapContent,
            isModalProcessing: state => state.isModalProcessing,
        })
    },
    methods: {
        ...mapActions('siteConfig/sitemap', [
            'closeModal',
            'setSitemapContent',
            'updateForm',
        ])
    }
}
</script>