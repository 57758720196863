<template>
    <vue-modal :visible="isModalOpen" @on-close="closeResetPasswordModal" id="reset-user-password-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-exclamation-triangle mr-1 text-tertiary"></span>Confirm Password Reset</h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">Are you sure you want to reset this users password?</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="closeResetPasswordModal">Cancel</button>
                <button type="button" class="btn btn-primary confirm-btn ml-2" @click="confirmResetPassword" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Reset Password' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'ConfirmResetPasswordModal',
        computed: {
            ...mapState('userManager/resetUserPasswordModal', {
                isModalOpen: state => state.isModalOpen,
                isModalProcessing: state => state.isModalProcessing,
            })
        },
        methods: {
            ...mapActions('userManager', {
                confirmResetPassword: 'resetUserPasswordModal/confirmResetPassword',
                closeResetPasswordModal: 'resetUserPasswordModal/closeModal',
            }),
        }
    }
</script>