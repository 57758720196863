<template>
    <div>
        <div
            class="nav-icon"
            v-for="vpanelModule in modules"
            :key="vpanelModule.name"
        >
            <a
                class="nav-icon-link"
                :href="vpanelModule.url"
                :title="`Manage ${vpanelModule.name} Module`"
            >
                <span :class="`fas fa-${vpanelModule.icon}`"></span>
                <p class="nav-icon-label">{{ vpanelModule.name }}</p>
            </a>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'modules'
        ]
    }
</script>