<template>
    <div class="sidebar-wrapper">
        <div class="logo">
            <a class="nav-icon-link" :href="dashboardRoute" :title="`${vpanelName} Dashboard`">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
                    <g class="svg-vpanel-logo" fill="#fff">
                        <path d="M24.7312,0.7168l2.543,6.1856c0.0186-0.0006,0.0368-0.0028,0.0556-0.0028h26.8817 c0.9898,0,1.7921,0.8023,1.7921,1.7921s-0.8023,1.7921-1.7921,1.7921H28.7465l2.2103,5.3763h19.6704 c0.9898,0,1.7921,0.8023,1.7921,1.7921c0,0.9898-0.8023,1.7921-1.7921,1.7921H32.4303l2.2103,5.3763H47.043 c0.9898,0,1.7921,0.8023,1.7921,1.7921c0,0.9898-0.8023,1.7921-1.7921,1.7921H36.1141l2.2103,5.3763h4.9552 c0.9898,0,1.7921,0.8023,1.7921,1.7921c0,0.9898-0.8023,1.7921-1.7921,1.7921h-3.4817l2.3576,5.7348h30.8732 c0.9897,0,1.7921,0.8024,1.7921,1.7921c0,0.9897-0.8024,1.7921-1.7921,1.7921H43.629l2.2103,5.3763h23.6051 c0.9897,0,1.7921,0.8024,1.7921,1.7921s-0.8024,1.7921-1.7921,1.7921H47.3128l2.2103,5.3763h16.3371 c0.9897,0,1.7921,0.8024,1.7921,1.7921c0,0.9897-0.8024,1.7921-1.7921,1.7921H50.9966l2.2103,5.3763h9.0691 c0.9897,0,1.7921,0.8024,1.7921,1.7921c0,0.9897-0.8024,1.7921-1.7921,1.7921h-7.5956l7.3268,18.5484L100,0.7168H24.7312z M79.0035,33.9802c-0.7749,1.8127-3.0217,3.2957-4.9931,3.2957H53.9674c-1.9713,0-2.9503-1.4831-2.1754-3.2957l10.2439-23.9641 c0.7749-1.8127,3.0217-3.2957,4.9931-3.2957H87.072c1.9713,0,2.9503,1.4831,2.1754,3.2957L79.0035,33.9802z"/>
                        <polygon points="40.3226,99.2832 0,0.7168 17.9211,0.7168 58.2437,99.2832 "/>
                    </g>
                </svg>
                <p class="nav-icon-label">Home</p>
            </a>
        </div>
        <div class="sidebar-nav-list" v-if="navigationLinks || modules">
            <div
                class="nav-icon"
                v-for="(link, index) in navigationLinks"
                :key="index"
            >
                <a
                    class="nav-icon-link"
                    :href="link.path"
                    :title="`Manage ${link.title}`"
                >
                    <span :class="link.icon"></span>
                    <p class="nav-icon-label">{{ link.label }}</p>
                </a>
            </div>

            <module-pop-out-menu
                v-if="modules.length > 3"
                :modules="modules"
            ></module-pop-out-menu>

            <module-navigation-links
                v-else
                :modules="modules"
            ></module-navigation-links>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'modules',
            'logoPath',
            'vpanelName',
            'dashboardRoute',
            'navigationLinks',
        ]
    }
</script>